import React from "react";
import { useTranslation } from "react-i18next";
import { ADD_TO_SLACK } from "../../constants";
import { logEvent, EventActions, EventCategories } from "../../utils/logEvent";
import "./Top.css";

const Top = () => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col mt-8 px-6 pb-4 lg:pb-16 w-full">
      <article className="bg-dialog rounded-tr-3xl rounded-br-3xl leading-10 rounded-bl-3xl p-8 text-xl lg:text-4xl font-bold text-white w-full lg:w-6/12 mx-auto mb-8 lg:mb-32">
        {t("greeting")}
      </article>
      <a
        className="px-8 lg:px-16 py-3 lg:py-6 mx-auto lg:ml-auto lg:mr-40 bg-action border-4 border-black rounded-full font-extrabold text-highlight text-xl lg:text-3xl custom-stroke"
        href={ADD_TO_SLACK}
        onClick={() =>
          logEvent(EventActions.ADD_TO_SLACK, EventCategories.CALL_TO_ACTION)
        }
      >
        {t("addToSlack")} 🙌
      </a>
    </section>
  );
};

export default Top;
