import React from "react";
import { useTranslation } from "react-i18next";

const Contents = [
  {
    img: {
      src: "question.svg",
      alt: "question",
    },
    title: "contentQuestionTitle",
    description: "contentQuestionDescription",
  },
  {
    img: {
      src: "chart.svg",
      alt: "chart",
    },
    title: "contentVisualizationTitle",
    description: "contentVisualizationDescription",
  },
  {
    img: {
      src: "dialog.svg",
      alt: "dialog",
    },
    title: "contentDialogTitle",
    description: "contentDialogDescription",
  },
];

const Intro = () => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col items-center w-full p-8 lg:p-20">
      <div className="mb-16">
        <h2 className="mb-9 text-4xl font-bold text-center">{t("introTitle")}</h2>
        <ul className="leading-relaxed list-disc">
          <li>{t("introDescriptionOne")}</li>
          <li>{t("introDescriptionTwo")}</li>
          <li>{t("introDescriptionThree")}</li>
        </ul>
      </div>

      <div className="flex flex-col w-full md:flex-row mx-auto gap-y-4 gap-x-6">
        {Contents.map((content) => (
          <article
            key={content.alt}
            className="px-8 py-6 w-full border-4 border-black rounded-2xl bg-intro"
          >
            <img
              className="w-full mb-8"
              src={content.img.src}
              alt={content.img.alt}
              width="384"
              height="355"
            />
            <h4 className="text-3xl font-bold mb-4">{t(content.title)}</h4>
            <p>{t(content.description)}</p>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Intro;
