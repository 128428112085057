import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Top from "./components/Top/Top";
import Problem from "./components/Problem";
import Privacy from "./components/Privacy/Privacy";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="bg-gray-50 font-sans">
            <Header />
            <Top />
            <Problem />
            <Intro />
            <Footer />
          </div>
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
