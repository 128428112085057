import React from "react";

const SocialLinks = [
  {
    url: "mailto:jasperwu314@gmail.com",
    alt: "Email",
    img: "mail.svg",
  },
  {
    url: "https://www.facebook.com/creativemashimashi/",
    alt: "Facebook",
    img: "facebook.svg",
  },

  {
    url: "https://twitter.com/creativemashi2",
    alt: "Twitter",
    img: "twitter.svg",
  },
];

const Footer = () => (
  <footer className="flex flex-col w-full justify-center items-center py-4">
    <ul className="flex mb-4 gap-x-6">
      {SocialLinks.map((link) => (
        <li key={link.alt}>
          <a href={link.url}>
            <img src={link.img} alt={link.alt} width="24" height="24" />
          </a>
        </li>
      ))}
    </ul>
    <p className="text-sm font-bold">©2021 Mashimahsi, Inc</p>
  </footer>
);

export default Footer;
