export const EventActions = {
  ADD_TO_SLACK: "add_to_slack",
};

export const EventCategories = {
  CALL_TO_ACTION: "call_to_action",
};

export const logEvent = (action, category) => {
  if (process.env.NODE_ENV === "production") {
    // gtag is a global function set up in index.html
    // eslint-disable-next-line no-undef
    gtag("event", action, {
      event_category: category,
      event_label: "dowel_landing_page",
    });
  } else {
    console.log("log event:", { action, category });
  }
};
