import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ADD_TO_SLACK } from "../constants";
import { logEvent, EventActions, EventCategories } from "../utils/logEvent";

const Links = [
  {
    url: "http://creativemashimashi.com/",
    title: "home",
  },
  {
    url: "/how_it_works.pdf",
    title: "howItWorks",
  },
  {
    url: "/privacy",
    title: "privacyPolicy",
  },
];

const Header = () => {
  const [isTop, setIsTop] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = (e) => {
      if (window.scrollY > 20) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const toggleLanguage = async (lang) => {
    await i18next.changeLanguage(lang);
  };
  return (
    <header
      className={`flex flex-col lg:flex-row w-full bg-gray-50 px-10 pt-4 ${
        isTop
          ? "lg:pt-14 lg:px-40"
          : "lg:fixed items-center lg:h-24 lg:inset-0 lg:px-16 lg:py-5"
      }`}
    >
      <div className="relative mx-auto">
        <img
          className={`w-44 h-44 ${isTop ? "" : "lg:w-14 lg:h-14"}`}
          src="logo.svg"
          alt="logo"
          width="180"
          height="180"
        />
        <div
          className={`${
            isTop ? "" : "hidden"
          } absolute w-8 h-8 -right-2 -bottom-2 rounded-full bg-yellow-500`}
        />
      </div>
      <h1 className="font-bold text-4xl mx-auto lg:ml-5 mb-6">Dowel</h1>
      <nav
        className={`flex flex-col lg:flex-row gap-y-6 w-full lg:flex-1 mx-auto lg:ml-16 ${
          isTop ? "lg:justify-between" : ""
        }`}
      >
        <ul className="flex font-bold text-xl gap-x-12 mx-auto">
          {Links.map((link) => (
            <li key={link.title} className="hover:opacity-50">
              <a href={link.url}>{t(link.title)}</a>
            </li>
          ))}
        </ul>
        <ul className="mx-auto lg:ml-16">
          <li className="flex gap-x-4">
            <button
              onClick={() => {
                toggleLanguage("en");
              }}
              className="h-7 hover:opacity-50 font-bold"
            >
              EN
            </button>
            <span className="font-bold">
              /
            </span>
            <button
              onClick={() => {
                toggleLanguage("ja");
              }}
              className="h-7 hover:opacity-50 font-bold"
            >
              JP
            </button>
          </li>
        </ul>
      </nav>
      <a
        className={`hidden ${
          isTop
            ? ""
            : "lg:flex justify-center items-center bg-action rounded-3xl text-xl font-bold h-7 px-11 py-6"
        } `}
        href={ADD_TO_SLACK}
        onClick={() =>
          logEvent(EventActions.ADD_TO_SLACK, EventCategories.CALL_TO_ACTION)
        }
      >
        {t("addToSlack")}
      </a>
    </header>
  );
};

export default Header;
