import './Privacy.css';

const Privacy = () => (
  <div id="Privacy">
    <h1>Privacy Policy</h1>
    <h4>Version 1.0</h4>
    <h3>Last update: May 1st, 2021</h3>
    <p>
      Thank you for using Dowel. This Privacy Policy explains our practices
      regarding the collection, use, and disclosure of information we collect
      from users of our Services, including our bot and website. This Privacy
      Policy does not apply to any third-party websites, services, or
      applications, even if they are accessible through our Services. This
      Privacy Policy applies to all products and services offered by Dowel. By
      accessing or using our Services, you signify that you have read,
      understood, and agree to our collection, storage, use, and disclosure of
      your personal information as described in this Privacy Policy.
    </p>
    <h2>Data Collection and Use</h2>
    <p>
      Our primary goals in collecting data are to provide and improve our
      services, to administer your use of our services, and to enable you to
      enjoy and easily navigate our service.
    </p>
    <h3>What Data Collected Through Our Site</h3>
    <ul>
      <li>User name</li>
      <li>
        Data about our Service progress (including questions you designed and
        personal preference user selected)
      </li>
      <li>Advertising ID</li>
      <li>
        Data about your device, such as device name and operating system,
        browser type and language
      </li>
      <li>
        Data we collect with cookies and similar technologies (see more below)
      </li>
      <li>General location data</li>
      <li>Precise geo-location data (GPS, with your consent)</li>
    </ul>
    <h2>Data Collected Using Cookies and other Web Technologies</h2>
    <p>
      Like many website owners and operators, we use automated data collection
      tools such as Cookies and Web Beacons to collect certain data.
    </p>
    <h2>WHY DO WE COLLECT YOUR DATA</h2>
    <p>To make the Service work.</p>
    <p>To perform the contract, we process data necessary to</p>
    <ul>
      <li>Operate the Service</li>
      <li>Provide and deliver services you request</li>
      <li>Send you Service-related communications</li>
      <li>To make the Service more suitable for our users.</li>
    </ul>
    <p>
      To provide a great Service to our players, we have a legitimate interest
      to collect and process necessary data to
    </p>
    <ul>
      <li>Update and develop player profiles</li>
      <li>Develop and improve the Service and user experience</li>
      <li>Manage our relationship with you</li>
      <li>Provide social features as part of the Service</li>
      <li>Customize your Service experience</li>
      <li>Respond to your comments and questions and provide user support</li>
    </ul>
    <h2>Data Protection</h2>
    <p>
      We take reasonable measures to protect the information that we collect
      from you or about you from unauthorized access, use, or disclosure. Please
      be aware, however, that no method of transmitting information over the
      Internet or storing information is completely secure. Accordingly, we
      cannot guarantee the absolute security of any information.
    </p>

    <h2>Data Retention Policy</h2>
    <p>
      Dowel takes data security and privacy very seriously. Data is stored as
      long as Dowel is installed on your workspace. You can request the removal
      of your data at any time by making a request to
      creativemashimashi@gmail.com. We always strive to collect and retain the
      minimum data necessary for the app to function.
    </p>
    <h2>Data archival/removal policy</h2>
    <p>
      You can submit a request to have your data deleted at any time after
      removing Dowel from your workspace. Send your request to{' '}
      <a href="mailto: creativemashimashi@gmail.com">
        creativemashimashi@gmail.com
      </a>{' '}
      and your data will be deleted within 30 days.
    </p>
  </div>
);

export default Privacy;
