const en = {
  translation: {
    home: "Home",
    howItWorks: "How it works",
    privacyPolicy: "Privacy Policy",
    greeting:
      "Hi👋 I am DOWEL. I am here to help your teams and groups to develop meaningful and engaging conversations.🤝",
    addToSlack: "Add to Slack",
    problemSubTitle: "Covid-19 has distanced everyone from others.",
    problemTitle:
      "Found hard time breaking the ice with new faces at remote work?",
    problemDescription:
      "Develop good relationships in this new normal can be as easy as few taps.",
    engageWithConversation: "Engage with conversation under 5 mins",
    introTitle: "More than an Icebreaker",
    introDescriptionOne: "Simple to use and joyful",
    introDescriptionTwo: "As the employee, break the ice with new faces",
    introDescriptionThree:
      "As the new hire, get invovled with team conversation",
    contentQuestionTitle: "Tell the team about yourself",
    contentQuestionDescription:
      "An interesting and quick way to introduce yourself by answering a few questions.",
    contentVisualizationTitle: "Visualization of the team answers",
    contentVisualizationDescription:
      "Visualize and map each one's taste and personality.",
    contentDialogTitle: "Engaging conversations",
    contentDialogDescription:
      "Easily initiate a conversation based on the result.",
  },
};
export default en;
