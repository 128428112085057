import React from "react";
import { useTranslation } from "react-i18next";
import { ADD_TO_SLACK } from "../constants";
import { logEvent, EventActions, EventCategories } from "../utils/logEvent";

const Problem = () => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col md:flex-row justify-between p-8 lg:p-20">
      <article className="pt-14">
        <h3 className="mb-6 text-lg font-bold">{t("problemSubTitle")}</h3>
        <h2 className="mb-9 text-3xl lg:text-6xl leading-tight font-bold">
          {t("problemTitle")}
        </h2>
        <p className="text-xl mb-12">{t("problemDescription")}</p>
        <a
          className="py-3 px-11 bg-action rounded-full font-bold text-xl"
          href={ADD_TO_SLACK}
          onClick={() =>
            logEvent(EventActions.ADD_TO_SLACK, EventCategories.CALL_TO_ACTION)
          }
        >
          {t("addToSlack")}
        </a>
        <p className="pt-5 mb-4">
          <span className="text-sm"> {t("engageWithConversation")}</span>
        </p>
      </article>
      <img
        className="w-full md:w-1/3"
        src="virus.svg"
        width="397"
        height="520"
        alt="virus"
      />
    </section>
  );
};
export default Problem;
