const ja = {
  translation: {
    home: "ホーム",
    howItWorks: "使い方",
    privacyPolicy: "個人情報保護方針",
    greeting:
      "こんにちは👋私の名前はDowelです。私はあなたのチームに会話のきっかけを提供し、良いチームワークの実現をサポートします🤝",
    addToSlack: "Slackにダウンロード",
    problemSubTitle: "コロナウイルスは人々を引き離しました。",
    problemTitle:
      "オンラインで初めて会った人と仲良くなるのに苦戦された経験はないですか？",
    problemDescription:
      "Dowelで手軽に新しいチームメンバーと仲良くなりましょう！",
    engageWithConversation: "５分でぎこちなさとオサラバ！",
    introTitle: "ただのアイスブレイカーではありません。",
    introDescriptionOne: "シンプルなデザインで楽しく使える",
    introDescriptionTwo:
      "既存メンバーとして、新しいチームメンバーと早く仲良くなれる",
    introDescriptionThree:
      "新人として、既存のメンバーと仲良くなるきっかけになる",
    contentQuestionTitle: "チームに自分自身について伝えてみましょう！",
    contentQuestionDescription:
      "いくつかの質問に答えるだけで、サクッと楽しく自己紹介ができます。",
    contentVisualizationTitle: "チームの回答を可視化します。",
    contentVisualizationDescription: "チームメンバーの好みや性格が一目瞭然",
    contentDialogTitle: "会話のきっかけに",
    contentDialogDescription: "質問の回答を元に話しかけてみましょう！",
  },
};

export default ja;
